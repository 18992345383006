import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {UsersIcon} from "@heroicons/react/24/solid";

export default function EnergetischeBaubegleitung() {
    return <>
        <Section id={'energetische-baubegeleitung'}>
            <div className={'flex justify-center'}>
                <UsersIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'energetische-baubegeleitung-headline'} className={'mt-4'}>Energetische Baubegleitung</H3>
            <Paragraph className={'mt-8'}>
                Bei der Umsetzung von Sanierungsmaßnahmen wie die Fassadendämmung oder der Fensteraustausch
                ist eine energetische Baubegleitung elementar, um die fachgerechte Bauausführung sicherzustellen.
                Für den Erhalt einer möglichen Förderung ist ein Energie-Effizienz-Experte miteinzubeziehen.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Wir als Energie-Effizienz-Experten nehmen Sie in jedem Schritt des Förderprozesses mit und
                zeigen Ihnen, worauf es bei der energetischen Sanierung Ihrer Liegenschaft ankommt.
            </Paragraph>
        </Section>
    </>
}