import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {IdentificationIcon} from "@heroicons/react/24/solid";

export default function Energieausweis() {
    return <>
        <Section id={'energieausweis'}>
            <div className={'flex justify-center'}>
                <IdentificationIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'energieausweis-headline'} className={'mt-4'}>Energieausweis</H3>
            <Paragraph className={'mt-8'}>
                Zur Vergleichbarkeit des energetischen Standards von Immobilien wurde der Energieausweis eingeführt.
                Er soll bei Verkauf oder Vermietung einen Überblick über den energetischen Standard der Immobilie
                ermöglichen.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Sollten Sie Ihre Immobilie vermieten oder veräußern wollen, ist ein Energieausweis gesetzlich verpflichtend.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Welcher Energieausweis für Ihre Immobilie erstellt werden muss, erfahren Sie von unseren Experten.
            </Paragraph>
        </Section>
    </>
}