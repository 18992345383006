import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {HomeIcon} from "@heroicons/react/24/solid";

export default function EnergieberatungHauskauf() {
    return <>
        <Section id={'energieberatung-zum-hauskauf'}>
            <div className={'flex justify-center'}>
                <HomeIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'energieberatung-zum-hauskauf-headline'} className={'mt-4'}>Energieberatung vor Kauf einer Wohnimmobilie</H3>
            <Paragraph className={'mt-8'}>
                Viele unserer Kunden kommen nach dem Hauskauf auf uns zu und möchten mit uns einen Fahrplan
                zur energetischen Sanierung erarbeiten. Lassen Sie uns bereits davor teilhaben und eine
                gemeinsame Übersicht der Schwachstellen und Verbesserungen für Ihre Immobilie erstellen.
                Dies kann Ihren Verhandlungsspielraum erweitern und Ihre Kalkulation optimieren.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Eine Energieberatung vor dem Kauf einer Wohnimmobilie gibt Ihnen eine Einschätzung über
                den energetischen Zustand des Gebäudes und ermöglicht eine fundierte Entscheidung im Kaufprozess.
                Gerne erstellen wir Ihnen nach einer gemeinsamen Vor-Ort-Begehung ein übersichtliches
                Beratungsprotokoll einschließlich einer Schwachstellenanalyse.
            </Paragraph>
        </Section>
    </>
}