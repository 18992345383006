import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {ChatBubbleLeftRightIcon} from "@heroicons/react/24/solid";

export default function Foerderberatung() {
    return <>
        <Section id={'foerderberatung'}>
            <div className={'flex justify-center'}>
                <ChatBubbleLeftRightIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'foerderberatung-headline'} className={'mt-4'}>Förderberatung</H3>
            <Paragraph className={'mt-8'}>
                In Deutschland gibt es vielfältige Möglichkeiten die energetische Sanierung oder den Bau Ihrer
                Immobilie mit einem zinsvergünstigten Kredit oder einem Zuschuss fördern zu lassen. Dabei kann
                man auf verschiedene Programme zur Förderung zurückgreifen. Wir beraten Sie gerne, welches Produkt
                für Ihre Maßnahmen in Frage kommt. Als gelistete Energie-Effizienz-Experten übernehmen wir auch
                gerne die gesamte Begleitung der Baumaßnahmen.
            </Paragraph>
        </Section>
    </>
}