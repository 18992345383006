import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {PresentationChartLineIcon} from "@heroicons/react/24/solid";

export default function Wirtschaftlichkeitsberechnung() {
    return <>
        <Section id={'wirtschaftlichkeitsberechnung'}>
            <div className={'flex justify-center'}>
                <PresentationChartLineIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'wirtschaftlichkeitsberechnung-headline'} className={'mt-4'}>Wirtschaftlichkeitsberechnung</H3>
            <Paragraph className={'mt-8'}>
                Um die Energiesparmaßnahmen im Verlauf ihres Betrachtungszeitraums monetär abbilden zu können,
                ist eine Wirtschaftlichkeitsberechnungen sinnvoll. Somit haben Sie eine Übersicht der Vergleichbarkeit
                der für Sie infrage kommenden Heizungstechnik.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Gerne erstellen wir Ihnen unter Berücksichtigung des Energiebedarfs Ihrer Immobilie einen Variantenvergleich.
            </Paragraph>
        </Section>
    </>
}