import {H2} from "../Headlines";
import {CalendarDateRangeIcon, ChatBubbleLeftRightIcon, DocumentTextIcon, FireIcon, HomeIcon, HomeModernIcon, IdentificationIcon, PresentationChartLineIcon, UsersIcon} from "@heroicons/react/24/solid";
import Paragraph from "../Paragraph";
import Section from "./Section";
import Energieausweis from "./Energieausweis";
import Foerderberatung from "./Foerderberatung";
import Heizlastberechnung from "./Heizlastberechnung";
import ISFP from "./ISFP";
import EnergieberatungHauskauf from "./EnergieberatungHauskauf";
import NachweisAnteilErneuerbareEnergien from "./NachweisAnteilErneuerbareEnergien";
import EnergetischeBaubegleitung from "./EnergetischeBaubegleitung";
import Wirtschaftlichkeitsberechnung from "./Wirtschaftlichkeitsberechnung";
import React from "react";

export default function Leistungen() {
    return <>
        <div className={'devide-y divide-gray-200 '}>
            <Section id={'leistungen'}>
                <H2 id={'leistungen-headline'} className={'mb-8'}>Unsere Leistungen</H2>
                {/*<HomeModernIcon className={'text-green-600'}/>*/}
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#foerderberatung'}><ChatBubbleLeftRightIcon className={'text-green-600 h-6 mr-2'}/>Förderberatung</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#energieausweis'}><IdentificationIcon className={'text-green-600 h-6 mr-2'}/>Energieausweis</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#wirtschaftlichkeitsberechnung'}><PresentationChartLineIcon className={'text-green-600 h-6 mr-2'}/>Wirtschaftlichkeitsberechnung</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#energetische-baubegeleitung'}><UsersIcon className={'text-green-600 h-6 mr-2'}/>Energetische Baubegleitung</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#energieberatung-zum-hauskauf'}><HomeIcon className={'text-green-600 h-6 mr-2'}/>Energieberatung zum Hauskauf</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#isfp-individueller-sanierungsfahrplan'}><CalendarDateRangeIcon className={'text-green-600 h-6 mr-2'}/>Individueller Sanierungsfahrplan (ISFP)</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#heizlastberechnung'}><FireIcon className={'text-green-600 h-6 mr-2'}/>Heizlastberechnung</a></Paragraph>
                <Paragraph className={'mt-2'}><a className={'flex hover:text-gray-900'} href={'#nachweis-anteil-erneuerbarer-energien'}><DocumentTextIcon className={'text-green-600 h-6 mr-2'}/>Nachweis Anteil erneuerbarer Energien</a></Paragraph>
            </Section>


            <Foerderberatung/>
            <Energieausweis/>
            <Wirtschaftlichkeitsberechnung/>
            <EnergetischeBaubegleitung/>
            <EnergieberatungHauskauf/>
            <ISFP/>
            <Heizlastberechnung/>
            <NachweisAnteilErneuerbareEnergien/>
        </div>

    </>
}