import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {FireIcon} from "@heroicons/react/24/solid";

export default function Heizlastberechnung() {
    return <>
        <Section id={'heizlastberechnung'}>
            <div className={'flex justify-center'}>
                <FireIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'heizlastberechnung-headline'} className={'mt-4'}>Heizlastberechnung</H3>
            <Paragraph className={'mt-8'}>
                Die Heizlastberechnung ist die Grundvoraussetzung für energieeffizientes Heizen und insbesondere bei
                einem Heizungsaustausch elementar.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Anhand der Ermittlung und Berücksichtigung aller Wärmeverluste und -gewinne können wir die für Ihr
                Gebäude benötigte Leistung des Wärmeerzeugers bestimmen.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Dies spart Ihnen im laufenden Betrieb kontinuierlich Energie sowie Heizkosten.
            </Paragraph>
        </Section>
    </>
}