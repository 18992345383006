import React from 'react';
import Kontakt from "./components/Section/Kontakt";
import Header from "./components/Section/Header";
import Leistungen from "./components/Section/Leistungen";
import UnserTeam from "./components/Section/UnserTeam";
import Navigation from "./components/Navigation";
import Impressum from "./components/Section/Impressum";

function App() {
  return <>
    <Navigation/>
    <div className="bg-white px-6 py-16 sm:py-24 lg:px-8">
      <div className="mx-auto lg:max-w-4xl md:max-w-3xl sm:max-w-2xl text-center text-balance object-center items-center">
        <Header/>
        <Leistungen/>
        <UnserTeam/>
        <Kontakt/>
        <Impressum/>
      </div>
    </div>
  </>
}

export default App;
