import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {CalendarDateRangeIcon} from "@heroicons/react/24/solid";

export default function ISFP() {
    return <>
        <Section id={'isfp-individueller-sanierungsfahrplan'}>
            <div className={'flex justify-center'}>
                <CalendarDateRangeIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'isfp-individueller-sanierungsfahrplan-headline'} className={'mt-4'}>Individueller Sanierungsfahrplan (ISFP)</H3>
            <Paragraph className={'mt-8'}>
                Der Individuelle Sanierungsfahrplan (ISFP) ist ein gefördertes Beratungsinstrument, das Ihnen
                einen langfristigen und detaillierten Überblick über mögliche Sanierungsmaßnahmen und deren
                Einsparpotenziale an Ihrer Immobilie aufzeigt.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Durch das Umsetzen von möglichen Modernisierungen steigern Sie nicht nur den Wert Ihrer
                Immobilie und den Wohnkomfort, sondern beteiligen sich auch an der Erfüllung der vom
                Bund festgelegten Klimaschutzziele. Für die Beratung in Form eines ISFP gewährt Ihnen
                der Bund ein signifikant höheres Fördervolumen für die Durchführung Ihrer effizienzsteigernden Maßnahmen.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Lassen Sie uns bei einem vor Ort Termin Ihre Immobilie ganzheitlich betrachten und ein auf Sie und
                Ihre Wünsche abgestimmtes Sanierungskonzept entwickeln.
            </Paragraph>
        </Section>
    </>
}