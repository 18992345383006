import {ReactNode} from "react";

export function H1({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h1 id={id} className={`text-4xl sm:text-6xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h1>
    </>
}

export function H2({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h2 id={id} className={`text-3xl sm:text-5xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h2>
    </>
}

export function H3({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h3 id={id} className={`text-2xl sm:text-4xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h3>
    </>
}

export function H4({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h4 id={id} className={`text-xl sm:text-3xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h4>
    </>
}

export function H5({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h5 id={id} className={`text-xl sm:text-2xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h5>
    </>
}

export function H6({children, id, className}: { children: ReactNode, id: string, className?: string }) {
    return <>
        <h6 id={id} className={`text-xl sm:text-xl pt-2 font-bold tracking-tight text-gray-900 ${className || ''}`}>{children}</h6>
    </>
}

export default function Headlines() {
    return <>
        <H1 id={'h1-test'}>Headline 1</H1>
        <H2 id={'h2-test'}>Headline 2</H2>
        <H3 id={'h3-test'}>Headline 3</H3>
        <H4 id={'h4-test'}>Headline 4</H4>
        <H5 id={'h5-test'}>Headline 5</H5>
        <H6 id={'h6-test'}>Headline 6</H6>
    </>
}
