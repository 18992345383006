import {H2} from "../Headlines";
import {BuildingOffice2Icon, EnvelopeIcon, PhoneIcon} from "@heroicons/react/20/solid";
import React from "react";
import Paragraph from "../Paragraph";
import Section from "./Section";

export default function Kontakt() {
    return <>
        <Section id={'kontakt'} className="relative pb-20 lg:static">
            <H2 id={'kontakt-headline'}>Kontakt</H2>
            <Paragraph className="mt-6">
                Unser Team der OMW-Energieberatung freut sich auf Ihre Kontaktanfrage.
            </Paragraph>
            <div className={'px-6 lg:px-8'}>
                <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600 text-left">
                    <div className="flex gap-x-4">
                        <dt className="flex-none">
                            <span className="sr-only">Telefon</span>
                            <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                        </dt>
                        <dd>
                            <a href="tel:+4915566255593" className="hover:text-gray-900">
                                0155-66255593
                            </a>
                        </dd>
                    </div>
                    <div className="flex gap-x-4">
                        <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                        </dt>
                        <dd>
                            <a href="mailto:info@omw-energieberatungsbuero.de?subject=Kontaktanfrage%20Webseite" className="hover:text-gray-900">
                                info@omw-energieberatungsbuero.de
                            </a>
                        </dd>
                    </div>
                    <div className="flex gap-x-4">
                        <dt className="flex-none">
                            <span className="sr-only">Adresse</span>
                            <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                        </dt>
                        <dd>
                            OMW Energieberatungsbüro GbR
                            <br/>
                            Rhönblick 9
                            <br/>
                            63579 Freigericht
                        </dd>
                    </div>
                </dl>
            </div>
        </Section>
    </>
}