import Logo from "../Logo";
import {H1} from "../Headlines";
import React from "react";
import Paragraph, {HeadlineParagraph} from "../Paragraph";
import Section from "./Section";

export default function Header() {
    return <>
        <Logo/>
        <HeadlineParagraph>Energetische Verbesserung Ihrer Immobilie</HeadlineParagraph>
        <Section id={'home'}>
            <H1 id={'omw-energieberatung'}>OMW Energieberatung</H1>
            <Paragraph className="mt-12">
                Willkommen auf der Seite des OMW-Energieberatungsbüros. Wir haben es uns zur
                Aufgabe gemacht, unsere Kunden in allen Fragen der Energieeffizienz fachgerecht
                und transparent zu beraten.
            </Paragraph>
            <Paragraph className="mt-8">
                Wir laden Sie ein, sich einen Überblick über uns und unsere Tätigkeiten zu verschaffen.
                <br/>
                Lassen Sie uns gemeinsam Ihr Projekt zur energetischen Verbesserung Ihrer Immobilie umsetzen.
            </Paragraph>
            <Paragraph className="mt-8">
                Unser Team der OMW-Energieberatung freut sich auf <a href={'#kontakt'} className={'underline hover:text-gray-900'}>Ihre Kontaktanfrage</a>.
            </Paragraph>
        </Section>
    </>
}