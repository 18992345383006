import Section from "./Section";
import {H3} from "../Headlines";
import Paragraph from "../Paragraph";
import {DocumentTextIcon} from "@heroicons/react/24/solid";

export default function NachweisAnteilErneuerbareEnergien() {
    return <>
        <Section id={'nachweis-anteil-erneuerbarer-energien'}>
            <div className={'flex justify-center'}>
                <DocumentTextIcon className={'text-green-600 h-20'}/>
            </div>
            <H3 id={'nachweis-anteil-erneuerbarer-energien-headline'} className={'mt-4'}>Nachweis Anteil erneuerbarer Energien</H3>
            <Paragraph className={'mt-8'}>
                Seit Januar 2024 stellt das Gebäudeenergiegesetz umfangreiche Anforderungen an neu
                installierte Heizungsanlagen. Es besteht die Pflicht zur Nutzung von mindestens 65 %
                erneuerbarer Energien bei der Wärmeerzeugung.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Da Wohngebäude mit den unterschiedlichsten regenerativen Energieträgern beheizt werden,
                die jedoch bereits diese Gesetzesvorgaben einhalten, kann eine Bestimmung des
                entsprechenden Anteils der vorhandenen Anlagentechnik sinnvoll sein.
            </Paragraph>
            <Paragraph className={'mt-8'}>
                Wir bilanzieren Ihr Gebäude und erstellen Ihnen einen Nachweis über die Einhaltung des
                geforderten Erneuerbaren-Energien-Anteils unter Einhaltung der GEG-Vorgaben.
            </Paragraph>
        </Section>
    </>
}