import Section from "./Section";
import {H2} from "../Headlines";
import Paragraph from "../Paragraph";
import React from "react";

export default function Impressum() {
    return <>
        <Section id={'impressum'} className={'mt-40'}>
            <H2 id={'impressum-headline'}>Impressum</H2>
            <Paragraph className={'mt-8'}>OMW Energieberatungsbüro GbR, Rhönblick 9, 63579 Freigericht</Paragraph>
            <Paragraph className={'mt-2'}>Geschäftsführer: Tobias Michael-Weber und Benedikt Oppermann</Paragraph>
            <Paragraph className={'mt-2'}>Umsatzsteuer-Identifikationsnummer: DE365439193</Paragraph>
            <Paragraph className={'mt-2'}>Telefon: 0155-66255593, Email: info@omw-energieberatungsbuero.de</Paragraph>
        </Section>
    </>
}